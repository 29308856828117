import React, { useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import InstructorNotFoundIcon from "../../assets/icons/instructors-not-found-icon";
import InstructorRow from "../../components/instructors/instructor-row";
import InstructorsListFilters from "../../components/instructors/instructors-list-filters";
import InstructorsListStatus from "../../components/instructors/instructors-list-status";
import SubjectFilterModal from "../../components/instructors/subject-filter-modal/subject-filter-modal";
import PaginationComponent from "../../components/shared/pagination";
import { useLanguage } from "../../lib/language-context";
import useFilter from "../../lib/use-filter";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { instructorAPI } from "../../services/instructor-list";
import {
  actionReducers,
  initialState,
  reducer,
} from "./instructors-list-reducer";

const AllInstructorsPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [
    {
      loading,
      viewLimit,
      selected,
      subjectList,
      instructors,
      totalPages,
      totalInstructors,
      modal,
      filterModal,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const {
    setLoading,
    setInstructors,
    setModal,
    setSubjectList,
    setSelected,
    setFilterModal,
    setTotalPages,
    setTotalInstructors,
    setViewLimit,
  } = actionReducers(dispatch);

  const queryString = require("query-string");
  const location = useLocation();
  const history = useHistory();
  const parsed = queryString.parse(location.search, { arrayFormat: "comma" });

  const [page, setPage] = useFilter("page", parsed.page || 1);

  const limit = 10;

  const ModalAction = {
    open: (instructor) =>
      setModal({
        ...modal,
        instructor: { ...instructor },
        open: true,
      }),
    close: () => setModal({ open: false }),
    confirm: async () => {
      setModal({ ...modal, loading: "loading" });
      instructorAPI
        .payToView(modal.instructor._id)
        .then((res) => res.data)
        .then((data) => {
          window.open(data.InvoiceURL, "_blank");
        })
        .finally((data) => ModalAction.close());
    },
  };

  const fetchInstructorList = async (params) => {
    setLoading("loading");
    instructorAPI
      .listInstructors(params)
      .then((data) => {
        setInstructors(data.data);
        setTotalPages(data.totalPages);
        setViewLimit(data.instructorViewsLimit);
        setTotalInstructors(data.totalLength);
        setLoading("done");
      })
      .finally(() => {
        setLoading("done");
      });
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search, { arrayFormat: "comma" });
    const params = {
      ...parsed,
      limit,
    };
    fetchInstructorList(params);
  }, [location.search]);

  useEffect(() => {
    if (filterModal.open) return;
    if (selected.length < 1) {
      history.replace({ search: `` });
    } else {
      history.replace({
        search: `?subject${selected.length === 1 ? "[]" : ""}=${selected.join(
          ","
        )}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const InstructorsList = ({ instructors }) => (
    <div className="relative mb-10 bg-white px-2 py-1 rounded-xl min-h-[36rem] ">
      <div className="table-header mb-[16px] grid-cols-7 hover:shadow-none">
        <div>{selectedContent[localizationKeys.instructorName]}</div>
        <div className="mx-auto">
          {selectedContent[localizationKeys.eduLevel]}
        </div>
        <div className="mx-auto">
          {selectedContent[localizationKeys.subject]}
        </div>
        <div className="mx-auto">
          {selectedContent[localizationKeys.nationality]}
        </div>
        <div className="col-span-2 mx-auto">
          {selectedContent[localizationKeys.cv]}
        </div>
        <div className="mx-auto">{selectedContent[localizationKeys.view]}</div>
      </div>
      {instructors?.map((instructor) => (
        <InstructorRow
          key={instructor._id}
          instructor={instructor}
          modalOpen={() => ModalAction.open(instructor)}
        />
      ))}

      <div className="flex items-center justify-center mx-auto w-full mb-4 pb-4 mt-6">
        <PaginationComponent
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          className="absolute bottom-0 rtl:flex-row-reverse mb-4"
        />
      </div>
    </div>
  );

  return (
    <div className="px-8">
      <InstructorsListFilters
        filterState={{
          selected,
          filterModal,
          setFilterModal,
        }}
      />
      <InstructorsListStatus
        totalInstructors={totalInstructors}
        subjectsList={subjectList}
        states={{ setSelected, selected, limit: viewLimit }}
      />

      {loading === "loading" ? (
        <Loader active={true} />
      ) : instructors.length > 0 ? (
        <InstructorsList instructors={instructors} />
      ) : (
        <div className="bg-white px-2 py-1 rounded-xl h-[33rem] flex items-center justify-center">
          <div className="flex-col">
            <InstructorNotFoundIcon
              size="80"
              className="justify-center mx-auto text-center my-4"
            />
            <span className="text-xl text-primary text-center">
              {selectedContent[localizationKeys.instructorsNotFound]}
            </span>
          </div>
        </div>
      )}
      <SubjectFilterModal
        states={{
          filterModal,
          setFilterModal,
          selected,
          setSelected,
          subjectList,
          setSubjectList,
        }}
      />
    </div>
  );
};

export default AllInstructorsPage;
