import React from "react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import toast from "react-hot-toast";
import { Loader, Button, Confirm } from "semantic-ui-react";
import localizationKeys from "../../localization/localization-keys";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import { ImPencil, ImBin } from "react-icons/im";
import { BsPlus } from "react-icons/bs";
import AddEducationalOffice from "./add-educational-office";
import useFilter from "../../lib/use-filter";
import PaginationComponent from "../../components/shared/pagination";
// import SearchAndFilterEducationalOffices from "./search-and-filter-educational-offices";
import { useLocation } from "react-router-dom";
import EducationalOfficesNotFoundIcon from "../../assets/icons/educational-offices-notFound-icon";

const AllEducationalOfficesPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [forceReload, setForceReload] = React.useState(false);
  const [isAddingEducationalOffices, setIsAddingEducationalOffices] =
    React.useState(false);
  const [isEditingEducationalOffices, setIsEditingEducationalOffices] =
    React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [educationalOffice, setEducationalOffice] = React.useState([]);

  const [limit] = React.useState("9");
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = React.useState([]);

  const [page, setPage] = useFilter("page", 1);
  // const [gender, setGender] = useFilter("gender", "بنين");
  // const [city, setCity] = useFilter("city", "");

  const { run, isLoading } = useAxios({ data: [] });
  const { run: deleteEducationalOffice } = useAxios({ data: [] });
  const { run: getCities } = useAxios({});
  const [cities, setCities] = React.useState([]);

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);


  React.useEffect(() => {
    getCities(authAxios.get(api.city.all))
      .then(({ data }) => {
        let options = [];
        data?.data?.forEach((s) => {
          options.push({
            text: s.name,
            key: s._id,
            value: s._id,
          });
        });
        setCities(options);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });

    if (page < 1) setPage(1);
    if (page  && page !== "undefined")
      run(
        authAxios.get(
          `${api.educationalOffices.all}?limit=${limit}&page=${parsed?.page}
          `
        )
        // authAxios.get(
        //   `${api.educationalOffices.all}?limit=${limit}&page=${page}${
        //     parsed?.searchInput ? `&name=${parsed?.searchInput}` : ""
        //   }${gender ? `&gender=${gender}` : ""}${
        //     parsed?.city ? `&city=${parsed?.city}` : ""
        //   }
        //   `
        // )
      )
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1); 
          setData(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    run,
    forceReload,
    parsed?.page,
    limit,
    getCities,
    // parsed?.searchInput,
    // gender,
    // city,
    location.search,
  ]);

  const DeleteEducationalOffice = (id) => {
    deleteEducationalOffice(
      authAxios.delete(`${api.educationalOffices.all}/${id}`)
    )
      .then(() => {
        toast.success(selectedContent[localizationKeys.afterDeletionMessage]);
        setIsDeleting(false);
        setForceReload((p) => !p);
        setEducationalOffice("");
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  return (
    <div>
      {/* <SearchAndFilterEducationalOffices
        isLoading={isLoading}
        cities={cities}
        gender={gender}
        setGender={setGender}
        city={city}
        setCity={setCity}
      /> */}
      {isLoading ? (
        <Loader active={true} />
      ) : data?.data?.length > 0 ? (
        <>
          <div className="relative mt-6 mb-10 bg-white px-2 py-1 rounded-xl min-h-[33rem] ">
            <div className="w-full grid grid-cols-3 my-4 bg-gray-100 rounded-md text-lightGrey p-2">
              <div className="rtl:mr-16 ltr:ml-16">
                {selectedContent[localizationKeys.educationalOfficeName]}
              </div>
              <div className="rtl:mr-24 ltr:ml-24">
                {selectedContent[localizationKeys.city]}
              </div>
              <div className="rtl:mr-24 ltr:ml-24">
                {selectedContent[localizationKeys.action]}
              </div>
            </div>
            {data?.data?.map((d) => (
              <div
                className="w-full grid grid-cols-3 py-2 items-center break-words"
                key={d._id}
              >
                <div className=" flex items-center w-full rtl:mr-20 ltr:ml-20">
                  {d.name}
                </div>
                <div className="rtl:mr-24 ltr:ml-24 w-full">
                  <div>{d?.city?.name}</div>
                </div>
                <div className="flex items-center rtl:mr-24 ltr:ml-24">
                  <ImPencil
                    className="fill-lightGrey cursor-pointer"
                    onClick={() => {
                      setEducationalOffice(d);
                      setIsEditingEducationalOffices(true);
                    }}
                  />
                  <ImBin
                    className="mx-2 fill-red-600 cursor-pointer"
                    onClick={() => {
                      setEducationalOffice(d);
                      setIsDeleting(true);
                    }}
                  />
                </div>
              </div>
            ))}
            <div className="absolute bottom-0 flex items-center justify-between -mt-4 w-full">
              <div></div>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                className="my-8 flex justify-center rtl:flex-row-reverse"
              />
              <Button
                circular
                size="mini"
                className="bg-primary h-20 shadow-sm shadow-primary mx-8"
                onClick={() => setIsAddingEducationalOffices(true)}
              >
                <BsPlus size={36} className="fill-white" />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-white relative px-2 py-1 rounded-xl h-[33rem] flex items-center justify-center">
          <div className="flex-col">
            <EducationalOfficesNotFoundIcon
              size="80"
              className="justify-center mx-auto text-center my-4"
            />
            <span className="text-xl text-primary text-center">
              {selectedContent[localizationKeys.educationalOfficesNotFound]}
            </span>
          </div>
          <div className="absolute bottom-0 flex items-center justify-end -mt-4 w-full">
              <Button
                circular
                size="mini"
                className="bg-primary h-20 shadow-sm shadow-primary mx-8 mb-4"
                onClick={() => setIsAddingEducationalOffices(true)}
              >
                <BsPlus size={36} className="fill-white" />
              </Button>
            </div>
        </div>
      )}
      <AddEducationalOffice
        isOpen={isAddingEducationalOffices || isEditingEducationalOffices}
        onClose={() => {
          setIsAddingEducationalOffices(false);
          setIsEditingEducationalOffices(false);
          setEducationalOffice("");
        }}
        reload={() => setForceReload((p) => !p)}
        educationalOffice={educationalOffice}
        isEditingEducationalOffices={isEditingEducationalOffices}
        cities={cities}
      />
      <Confirm
        closeOnEscape
        className="text-center font-extrabold text-black text-xl "
        cancelButton={selectedContent[localizationKeys.cancel]}
        confirmButton={selectedContent[localizationKeys.delete]}
        content={selectedContent[localizationKeys.deleteEducationalOffice]}
        size="mini"
        open={isDeleting}
        onCancel={() => setIsDeleting(false)}
        onConfirm={() => DeleteEducationalOffice(educationalOffice?._id)}
      />
    </div>
  );
};

export default AllEducationalOfficesPage;
