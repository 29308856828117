import React from "react";
import { AiFillEye } from "react-icons/ai";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Accordion, Button } from "semantic-ui-react";
import api from "../../api";
import PdfIcon from "../../assets/icons/pdf-icon";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";

const InstructorProfileSections = ({ instructorId }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [openingSpecializations, setOpeningSpecializations] =
    React.useState(false);
  const [openingTrainingCourses, setOpeningTrainingCourses] =
    React.useState(false);
  const [openingWorkExperience, setOpeningWorkExperience] =
    React.useState(false);

  const { run: getSpecializations, data: specializations } = useAxios({});
  const { run: getTrainingCourses, data: trainingCourses } = useAxios({});
  const { run: getWorkExperience, data: workExperience } = useAxios({});

  React.useEffect(() => {
    getSpecializations(
      authAxios.get(`${api.specializations.instructor(instructorId)}`)
    );
  }, [getSpecializations, instructorId]);

  React.useEffect(() => {
    getTrainingCourses(
      authAxios.get(`${api.trainingCourses.instructor(instructorId)}`)
    );
  }, [getTrainingCourses, instructorId]);

  React.useEffect(() => {
    getWorkExperience(
      authAxios.get(`${api.workExperience.instructor(instructorId)}`)
    );
  }, [getWorkExperience, instructorId]);

  return (
    <>
      {specializations?.data?.length > 0 && (
        <Accordion fluid>
          <Accordion.Title
            className="flex items-center justify-between w-full h-[58px] text-base font-semibold
           mt-4 rounded-lg py-3 px-16 bg-white shadow-[0px_0px_6px_rgba(0,0,0,0.09)] "
            defaultActiveIndex={-1}
            index={0}
            onClick={() => {
              setOpeningSpecializations(!openingSpecializations);
              setOpeningTrainingCourses(false);
              setOpeningWorkExperience(false);
            }}
          >
            {selectedContent[localizationKeys.studies]}
            <div className="bg-[#177BA31A] w-7 h-7 rounded-full flex items-center justify-center">
              {!openingSpecializations && (
                <MdKeyboardArrowDown size={20} className="fill-primary" />
              )}
              {openingSpecializations && (
                <MdKeyboardArrowUp size={20} className="fill-primary" />
              )}
            </div>
          </Accordion.Title>
          <Accordion.Content
            active={openingSpecializations}
            className="mt-4 px-16"
          >
            <div className="flex gap-4 items-center flex-wrap mt-4">
              {specializations?.data?.map((d) => (
                <div className="w-[300px] h-[152px] rounded-lg ring-1 ring-[#C5E6F3] grid grid-cols-2 p-2">
                  <div className="px-3">
                    <div className="text-[#9B9B9B] mt-5">
                      {
                        selectedContent[
                          localizationKeys.educationalQualification
                        ]
                      }
                    </div>
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.specialization]}
                    </div>
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.graduationYear]}
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="mt-5">{d?.specializationDegree}</div>
                    <div className="mt-5">{d?.specialization}</div>
                    <div className="mt-5">{d?.graduationYear}</div>
                  </div>
                </div>
              ))}
            </div>
          </Accordion.Content>
        </Accordion>
      )}
      {trainingCourses?.data?.length > 0 && (
        <Accordion fluid>
          <Accordion.Title
            className="flex items-center justify-between w-full h-[58px] text-base font-semibold
           mt-4 rounded-lg py-3 px-16 bg-white shadow-[0px_0px_6px_rgba(0,0,0,0.09)] "
            defaultActiveIndex={-1}
            index={0}
            onClick={() => {
              setOpeningTrainingCourses(!openingTrainingCourses);
              setOpeningSpecializations(false);
              setOpeningWorkExperience(false);
            }}
          >
            {selectedContent[localizationKeys.trainingCourses]}
            <div className="bg-[#177BA31A] w-7 h-7 rounded-full flex items-center justify-center">
              {!openingTrainingCourses && (
                <MdKeyboardArrowDown size={20} className="fill-primary" />
              )}
              {openingTrainingCourses && (
                <MdKeyboardArrowUp size={20} className="fill-primary" />
              )}
            </div>
          </Accordion.Title>
          <Accordion.Content
            active={openingTrainingCourses}
            className="mt-4 px-16"
          >
            <div className="flex gap-4 items-center flex-wrap mt-4">
              {trainingCourses?.data?.map((d) => (
                <div className="w-[300px] h-[197px] rounded-lg ring-1 ring-[#C5E6F3] grid grid-cols-2 p-2">
                  <div className="px-3">
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.courseName]}
                    </div>
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.theOrganization]}
                    </div>
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.courseDuration]}
                    </div>
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.certificate]}
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="mt-5">{d?.courseName}</div>
                    <div className="mt-5">{d?.courseOrganization}</div>
                    <div className="mt-5">
                      {d?.courseDuration} {d?.durationType}
                    </div>
                    <div className="mt-5 flex items-center justify-between">
                      <div className=" flex items-center ">
                        <PdfIcon />
                        certificate.pdf
                      </div>
                      <Button
                        circular
                        icon
                        size="tiny"
                        disabled={!d?.imageLink}
                        className="bg-[#177BA31A] flex items-center justify-center p-1"
                      >
                        <AiFillEye
                          size={10}
                          className="fill-primary"
                          onClick={() => window.open(d?.imageLink)}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Accordion.Content>
        </Accordion>
      )}
      {workExperience?.data?.length > 0 && (
        <Accordion fluid>
          <Accordion.Title
            className="flex items-center justify-between w-full h-[58px] text-base font-semibold
           mt-4 rounded-lg py-3 px-16 bg-white shadow-[0px_0px_6px_rgba(0,0,0,0.09)] "
            defaultActiveIndex={-1}
            index={0}
            onClick={() => {
              setOpeningWorkExperience(!openingWorkExperience);
              setOpeningSpecializations(false);
              setOpeningTrainingCourses(false);
            }}
          >
            {selectedContent[localizationKeys.pastExperience]}
            <div className="bg-[#177BA31A] w-7 h-7 rounded-full flex items-center justify-center">
              {!openingWorkExperience && (
                <MdKeyboardArrowDown size={20} className="fill-primary" />
              )}
              {openingWorkExperience && (
                <MdKeyboardArrowUp size={20} className="fill-primary" />
              )}
            </div>
          </Accordion.Title>
          <Accordion.Content
            active={openingWorkExperience}
            className="mt-4 px-16"
          >
            <div className="flex gap-4 items-center flex-wrap mt-4">
              {workExperience?.data?.map((d) => (
                <div className="w-[300px] h-[152px] rounded-lg ring-1 ring-[#C5E6F3] grid grid-cols-2 p-2">
                  <div className="px-3">
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.jobName]}
                    </div>
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.schoolName]}
                    </div>
                    <div className="text-[#9B9B9B] mt-5">
                      {selectedContent[localizationKeys.yearsOfExperience]}
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="mt-5">{d?.jobTitle}</div>
                    <div className="mt-5">{d?.schoolName}</div>
                    <div className="mt-5">
                      {d?.duration} {d?.durationType}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Accordion.Content>
        </Accordion>
      )}
    </>
  );
};

export default InstructorProfileSections;
