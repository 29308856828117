const localizationKeys = {
  actions: "actions",
  login: "login",
  loginButton: "loginButton",
  registration: "registration",
  email: "email",
  password: "password",
  forgotPassword: "forgotPassword",
  dontHaveAnAccont: "dontHaveAnAccont",
  required: "required",
  emailError: "emailError",
  subjects: "subjects",
  educationalOffices: "educationalOffices",
  schools: "schools",
  settings: "settings",
  logout: "logout",
  cancelButton: "cancelButton",
  confirmButton: "confirmButton",
  logoutMessage: "logoutMessage",
  admin: "admin",
  educationalOfficeName: "educationalOfficeName",
  city: "city",
  action: "action",
  addEducationalOffice: "addEducationalOffice",
  editEducationalOffice: "editEducationalOffice",
  gender: "gender",
  male: "male",
  female: "female",
  cancel: "cancel",
  create: "create",
  edit: "edit",
  deletedSuccessfully : "deletedSuccessfully",
  createdSuccessfully: "createdSuccessfully",
  editedSuccessfully: "editedSuccessfully",
  deleteMessage: "deleteMessage",
  afterDeletionMessage: "afterDeletionMessage",
  deleteEducationalOffice: "deleteEducationalOffice",
  delete: "delete",
  schoolName: "schoolName",
  educationalLevels: " educationalLevels",
  educationalOffice: "educationalOffice",
  type: "type",
  studentCategory: "studentCategory",
  watch: "watch",
  search: "search",
  subjectName: "subjectName",
  tracks: "tracks",
  deleteSubject: "deleteSubject",
  editSubject: "editSubject",
  addSubject: "addSubject",
  schoolType: "schoolType",
  jobs: "jobs",
  jobName: "jobName",
  school: "school",
  jobLocation: "jobLocation",
  workHoursType: "workHoursType",
  educationalLevel: "educationalLevel",
  state: "state",
  available: "available",
  closed: "closed",
  address: "address",
  phoneNumber: "phoneNumber",
  schoolPublishedJobs: "schoolPublishedJobs",
  jobDetails: "jobDetails",
  job: "job",
  subject: "subject",
  publishedDate: "publishedDate",
  numberOfApplied: "numberOfApplied",
  salary: "salary",
  SR: "SR",
  applicant: "applicant",
  acceptanceInstructions: "acceptanceInstructions",
  freeTrial: "freeTrial",
  all: "all",
  determinedAtTheInterview: "determinedAtTheInterview",
  typeOfInstructors: "typeOfInstructors",
  jobDescription: "jobDescription",
  language: "language",
  english: "english",
  arabic: "arabic",
  schoolDescription: "schoolDescription",
  notCompleted: "notCompleted",
  schoolJobsNotFound: "schoolJobsNotFound",
  subjectsNotFound: "subjectsNotFound",
  jobsNotFound: "jobsNotFound",
  educationalOfficesNotFound: "educationalOfficesNotFound",
  schoolsNotFound: "schoolsNotFounds",
  commercialRegistrationNumber: "commercialRegistrationNumber",
  instructors: "instructors",
  instructorName: "instructorName",
  cv: "cv",
  view: "view",
  track: "track",
  instructorsNotFound: "instructorsNotFound",
  hiddenIdentity: "hiddenIdentity",
  hiddenIdentityInfo: "hiddenIdentityInfo",
  nationality: "nationality",
  englishLanguageLevel: "englishLanguageLevel",
  studies: "studies",
  educationalQualification: "educationalQualification",
  specialization: "specialization",
  graduationYear: "graduationYear",
  courseName: "courseName",
  theOrganization: "theOrganization",
  courseDuration: "courseDuration",
  certificate: "certificate",
  pastExperience: "pastExperience",
  yearsOfExperience: "yearsOfExperience",
  ejada: "ejada",
  level: "level",
  instructor: "instructor",
  goToEjad: "goToEjad",

  female2: "female2",
  fileName: "fileName",
  filter: "filter",
  filterBySubject: "filterBySubject",
  followUsOn: "followUsOn",
  footer: "footer",
  instructorsPlural: "instructorsPlural",
  isNotViewed: "isNotViewed",
  isViewed: "isViewed",
  jobTitle: "jobTitle",
  download: "download",
  downloadFrom: "downloadFrom",
  drafted: "drafted",
  drafted2: "drafted2",
  eduLevel: "eduLevel",
  ejad: "ejad",
  errorCode: "errorCode",
  experienceYears: "experienceYears",
  failedMessage: "failedMessage",
  getItFrom: "getItFrom",
  gotEjadCertificate: "gotEjadCertificate",
  goToProfile: "goToProfile",
  loading: "loading",
  pastPublications: "pastPublications",
  pastTrainingCourses: "pastTrainingCourses",
  paying: "paying",
  payToView: "payToView",
  phone: "phone",
  phoneMustBeNineCharchters: "phoneMustBeNineCharchters",
  phoneMustBeNumber: "phoneMustBeNumber",
  phoneNumberPlaceHolder: "phoneNumberPlaceHolder",
  pleaseCompleteYourProfile: "pleaseCompleteYourProfile",
  pleaseCompleteYourProfileText: "pleaseCompleteYourProfileText",
  price: "price",
  privacyPolicyBody: "privacyPolicyBody",
  privacyPolicyTitle: "privacyPolicyTitle",
  profileIsCompletedSuccessfully: "profileIsCompletedSuccessfully",
  publicationsLeft: "publicationsLeft",
  publish: "publish",
  searchBySubject: "searchBySubject",
  send: "send",
  specializationDegree: "specializationDegree",
  status: "status",
  stepOne: "stepOne",
  stepTwo: "stepTwo",
  trainingCourses: "trainingCourses",
  userGuide: "userGuide",
  vacancies: "vacancies",
  vacancy: "vacancy",
  vacancyDetails: "vacancyDetails",
  courseOrganization: "courseOrganization",
  description: "description",
  updatedSuccessfully: "updatedSuccessfully",
  reset: "reset",
  deleteJob: "delete job",
  notifications: "notifications",
};

export default localizationKeys;
