import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Accordion, Button, Dropdown, Form } from "semantic-ui-react";
import FormikInput from "../../components/formik/formik-input";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import * as Yup from "yup";
import useAxios from "../../lib/use-axios";
import api from "../../api";
import toast from "react-hot-toast";
import { authAxios } from "../../config/axios-config";
import ChangePassword from "../../components/shared/ChangePassword";

const SettingsPage = () => {
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const [openingSpecializations, setOpeningSpecializations] = React.useState({
    id: "",
    isOpen: false,
  });
  const [data, setData] = React.useState([]);

  const [isChangePassword, setIsChangePassword] = useState(false);

  const onSendSchema = Yup.object({
    vacancyPrice: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .trim(),
    profileViewPrice: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .trim(),
  });

  const { run, isLoading } = useAxios();
  const onSend = (values) => {
    run(authAxios.put(api.sendPaymentPrices, values))
      .then(({ data }) => {
        toast.success(
          lang === "en" ? "update successfully" : "تم التعديل بنجاح"
        );
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  const { run: getrun, isLoading: getisLoading } = useAxios();
  useEffect(() => {
    getrun(authAxios.get(api.sendPaymentPrices))
      .then(({ data }) => {
        setData(data);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  }, [getrun, openingSpecializations]);

  return (
    <>
      {isChangePassword ? (
        // <>
        //   <ChangePassword isChange={true} />
        //   <div className="w-full text-end">
        //     <button
        //       onClick={() => setIsChangePassword(false)}
        //       type=""
        //       className=" text-blue-600 hover:text-blue-600 duration-300 my-3 text-lg font-medium underline"
        //     >
        //       {lang === "en" ? "back to settings" : "العوده الي الاعدادات"}{" "}
        //       &nbsp; &#8592;
        //     </button>
        //   </div>
        // </>
        ""
      ) : (
        <div className="bg-white h-[35rem] p-6 rounded-2xl shadow-sm">
          <div className="flex items-center ">
            <div className="font-extrabold rtl:ml-4 ltr:mr-4 ">
              {selectedContent[localizationKeys.language]}
            </div>
            <Dropdown
              text={
                lang === "ar"
                  ? selectedContent[localizationKeys.arabic]
                  : selectedContent[localizationKeys.english]
              }
              selection
            >
              <Dropdown.Menu>
                {lang === "ar" ? (
                  <Dropdown.Item
                    text={"English"}
                    onClick={() => setLang("en")}
                  />
                ) : (
                  <Dropdown.Item
                    text={"العربية"}
                    onClick={() => setLang("ar")}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Accordion fluid>
            <Accordion.Title
              className="flex items-center justify-between w-full h-[58px] text-base font-semibold
          mt-4 rounded-lg py-3 px-16 bg-white shadow-[0px_0px_6px_rgba(0,0,0,0.09)] "
              defaultActiveIndex={-1}
              index={0}
              id="price"
              onClick={() => {
                setOpeningSpecializations({
                  isOpen: !openingSpecializations.isOpen,
                  id: "price",
                });
              }}
            >
              {lang === "en" ? "Prices" : "التسعير"}
              <div className="bg-[#177BA31A] w-7 h-7 rounded-full flex items-center justify-center">
                {!openingSpecializations.isOpen && (
                  <MdKeyboardArrowDown size={20} className="fill-primary" />
                )}
                {openingSpecializations.isOpen && (
                  <MdKeyboardArrowUp size={20} className="fill-primary" />
                )}
              </div>
            </Accordion.Title>
            <Accordion.Content
              active={
                openingSpecializations.isOpen === true &&
                openingSpecializations.id === "price"
                  ? true
                  : false
              }
              className="mt-4 px-16"
            >
              <div className="">
                <div>
                  <Formik
                    initialValues={{
                      vacancyPrice: data?.data?.vacancyPrice || "",
                      profileViewPrice: data?.data?.profileViewPrice || "",
                    }}
                    validationSchema={onSendSchema}
                    onSubmit={onSend}
                    enableReinitialize
                    className=""
                  >
                    {(formik) => (
                      <Form
                        onSubmit={formik.handleSubmit}
                        loading={getisLoading || isLoading}
                        className="flex justify-between flex-wrap gap-x-6 "
                      >
                        {console.log(formik.errors)}
                        <FormikInput
                          name="vacancyPrice"
                          label={
                            lang === "en" ? "Vacancy Price" : "سعر الوظيفة"
                          }
                          type="number"
                          min="1"
                          placeholder={
                            lang === "en" ? "Vacancy Price" : "سعر الوظيفة"
                          }
                          className={"w-full"}
                        />
                        <FormikInput
                          name="profileViewPrice"
                          label={
                            lang === "en"
                              ? "Profile View Price"
                              : " سعر عرض الملف الشخصي"
                          }
                          type="number"
                          min="1"
                          placeholder={
                            lang === "en"
                              ? "Profile View Price"
                              : " سعر عرض الملف الشخصي"
                          }
                          className={"w-full"}
                        />
                        <Button
                          primary
                          className="mt-3.5 block  h-11"
                          type="submit"
                        >
                          {lang === "en" ? "Update Price" : "تحديث السعر"}
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Accordion.Content>
          </Accordion>
{/*
          <div className="my-4">
            <button
              type="submit"
              onClick={() => setIsChangePassword(true)}
              className=" text-dark bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {lang === "en" ? "change passwrod ?" : "تغير كلمة السر ؟"}
            </button>{" "}
          </div> */}
        </div>
      )}
    </>
  );
};

export default SettingsPage;
