import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import EducationalOfficesIcon from "../../assets/icons/educational-offices-icon";
import SubjectsIcon from "../../assets/icons/subjects-icon";
import SettingsIcon from "../../assets/icons/settings-icon";
import SchoolsIcon from "../../assets/icons/schools-icon";
import { MdOutlinePowerSettingsNew } from "react-icons/md";
import routes from "../../routes";
import localizationKeys from "../../localization/localization-keys";
import { Confirm, Image, Sidebar } from "semantic-ui-react";
import auth from "../../utils/auth";
import JobsIcon from "../../assets/icons/jobs-icon";
import { FaSlidersH } from "react-icons/fa";
import logo from "../../assets/pictures/logoOnBlueBackground.svg";
import InstructorIcon from "../../assets/icons/instructors-icon";

const SidebarComponent = ({ isVisible, isSmall, setIsVisible }) => {
  const [lang] = useLanguage();
  const location = useLocation();
  const selectedContent = content[lang];
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);

  return (
    <>
      <Sidebar
        className="bg-primary w-64 shadow-none rtl:pr-4 ltr:pl-4 overflow-hidden rounded-tl-3x"
        visible={!isSmall || isVisible}
        animation="overlay"
      >
        <div
          className={`flex items-center ${
            isSmall ? `justify-between` : `justify-center`
          } mb-4 py-4`}
        >
          {isSmall && (
            <FaSlidersH
              size={30}
              className="bg-[#FCFCFC] rounded-lg p-2 mx-2 hover:cursor-pointer fill-primary"
              onClick={() => setIsVisible(!isVisible)}
            />
          )}
          <Image src={logo} className="text-center mx-auto mt-4" />
          <div></div>
        </div>
        <SidebarLink
          to={routes.educationalOffices.all}
          text={selectedContent[localizationKeys.educationalOffices]}
          icon={EducationalOfficesIcon}
          isActive={location.pathname.startsWith(routes.educationalOffices.all)}
        />
        <SidebarLink
          to={routes.schools.all}
          text={selectedContent[localizationKeys.schools]}
          icon={SchoolsIcon}
          isActive={location.pathname.startsWith(routes.schools.all)}
        />
        <SidebarLink
          to={routes.instructors.all}
          text={selectedContent[localizationKeys.instructors]}
          icon={InstructorIcon}
          isActive={location.pathname.startsWith(routes.instructors.all)}
        />
        <SidebarLink
          to={routes.subjects.all}
          text={selectedContent[localizationKeys.subjects]}
          icon={SubjectsIcon}
          isActive={location.pathname.startsWith(routes.subjects.all)}
        />
        <SidebarLink
          to={routes.jobs.all}
          text={selectedContent[localizationKeys.jobs]}
          icon={JobsIcon}
          isActive={location.pathname.startsWith(routes.jobs.all)}
        />
        <SidebarLink
          to={routes.settings}
          text={selectedContent[localizationKeys.settings]}
          icon={SettingsIcon}
          isActive={location.pathname.startsWith(routes.settings)}
        />
        <SidebarLink
          onClick={() => setIsConfirmOpen(true)}
          text={selectedContent[localizationKeys.logout]}
          icon={MdOutlinePowerSettingsNew}
          isActive={location.pathname.startsWith(routes.logout)}
          isLogout={true}
        />
      </Sidebar>
      <Confirm
        closeOnEscape
        className="text-center font-extrabold text-black text-xl "
        cancelButton={selectedContent[localizationKeys.cancelButton]}
        confirmButton={selectedContent[localizationKeys.confirmButton]}
        content={selectedContent[localizationKeys.logoutMessage]}
        size="mini"
        open={isConfirmOpen}
        onCancel={() => setIsConfirmOpen(false)}
        onConfirm={auth.logout}
      />
    </>
  );
};

export default SidebarComponent;

const SidebarLink = ({ to, text, isActive, icon: Icon, onClick, isLogout }) => (
  <div className="relative bg-none ">
    {/* {isActive && (
        <div className="absolute top-0 h-4 mb-8 bg-green-300 w-full rounded-bl-3xl " />
      )} */}
    {isActive && (
      <div className="h-4 bg-[#FCFCFC] w-full -mt-2">
        <div className=" bg-primary h-4 rtl:rounded-bl-3xl ltr:rounded-br-3xl" />
      </div>
    )}
    <Link
      to={to}
      className={`flex items-center gap-4 rtl:pr-6 ltr:pl-6 hover:cursor-pointer ${
        isActive
          ? "text-primary bg-[#FCFCFC] rtl:rounded-r-full ltr:rounded-l-full "
          : ""
      } ${isLogout ? "fixed bottom-0 w-full" : ""}`}
      onClick={onClick}
    >
      {isActive ? (
        <Icon size="28px" className="fill-primary text-primary" />
      ) : (
        <Icon size="28px" className="fill-white text-white  " />
      )}
      <p
        className={`w-full mt-3 mb-4  ${
          isActive ? " text-primary" : "text-white"
        }`}
      >
        {text}
      </p>
    </Link>
    {isActive && (
      // <div className="absolute bottom-0 h-4 pt-4 bg-green-300 w-[17rem] rounded-tl-3xl" />
      <div className="h-4 bg-[#FCFCFC] w-full -mb-2">
        <div className=" bg-primary h-4 rtl:rounded-tl-3xl ltr:rounded-tr-3xl" />
      </div>
    )}
  </div>
);
