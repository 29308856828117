import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import * as Yup from "yup";
import FormikInput from "../../components/formik/formik-input";
import FormikSelect from "../../components/formik/formik-select";

const AddSubject = ({ isOpen, onClose, reload, isEditingSubject, subject }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [educationalLevels, setEducationalLevels] = React.useState([]);
  const [tracks, setTracks] = React.useState([]);

  const { run: postSubject, isLoading: loadPostingSubject } = useAxios({});
  const { run: editSubject, isLoading: loadEditingSubject } = useAxios({});
  const { run: getEducationalLevels } = useAxios({});
  const { run: getTracks } = useAxios({});

  const isSecondaryEducationalLevel = (education_levels) => {
    return educationalLevels.filter(
      (e) => education_levels.includes(e.value) && e.isSecondary
    )[0];
  };

  const addNewSubjectSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(selectedContent[localizationKeys.required]),
    education_levels: Yup.array()
      .min(1)
      .required(selectedContent[localizationKeys.required])
      .nullable(),
    secondary_tracks: Yup.array().when("education_levels", {
      is: (education_levels) => isSecondaryEducationalLevel(education_levels),
      then: Yup.array()
        .min(1)
        .required(selectedContent[localizationKeys.required])
        .nullable(),
      otherwise: Yup.array().optional(),
    }),
    // secondary_tracks: Yup.array().optional(),
  });

  const handleModalClose = () => {
    onClose();
  };

  React.useEffect(() => {
    getEducationalLevels(authAxios.get(`${api.educationalLevels.all}`))
      .then(({ data }) => {
        let options = [];

        data?.data.forEach((s) => {
          options.push({
            text: s.name,
            key: s._id,
            value: s._id,
            isSecondary: s.isSecondary,
          });
        });
        setEducationalLevels(options);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
    getTracks(authAxios.get(`${api.tracks.all}`))
      .then(({ data }) => {
        let options = [];

        data?.data.forEach((s) => {
          options.push({ text: s.name, key: s._id, value: s._id });
        });
        setTracks(options);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEducationalLevels, isOpen]);

  const onSubmit = (values) => {
    values?.secondary_tracks?.length === 0 && delete values?.secondary_tracks;
    if (isEditingSubject) {
      editSubject(authAxios.put(`${api.subjects.all}/${subject?._id}`, values))
        .then(() => {
          reload();
          handleModalClose();
          toast.success(selectedContent[localizationKeys.editedSuccessfully]);
        })
        .catch((e) => {
          e?.errors?.map((s) => toast.error(s.message));
        });
    } else {
      postSubject(authAxios.post(api.subjects.all, values))
        .then(() => {
          reload();
          handleModalClose();
          toast.success(selectedContent[localizationKeys.createdSuccessfully]);
        })
        .catch((e) => {
          e?.errors?.map((s) => toast.error(s.message));
        });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      size="tiny"
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className="max-w-screen-2xl "
    >
      <h3 className="text-lg text-center font-extrabold pt-6">
        {isEditingSubject
          ? selectedContent[localizationKeys.editSubject]
          : selectedContent[localizationKeys.addSubject]}
      </h3>
      <Modal.Content>
        <Formik
          initialValues={{
            name: subject?.name ?? "",
            education_levels:
              subject?.education_levels?.map((a) => a?._id) || [],
            secondary_tracks:
              subject?.secondary_tracks?.map((a) => a?._id) || [],
          }}
          onSubmit={onSubmit}
          validationSchema={addNewSubjectSchema}
          enableReinitialize
        >
          {(formik) => (
            <Form
              onSubmit={formik.handleSubmit}
              loading={loadEditingSubject || loadPostingSubject}
            >
              {console.log(formik.errors)}
              {console.log(formik.values)}
              <div className="grid grid-cols-2 items-center justify-center w-56">
                <span className="text-center font-bold mt-4">
                  {selectedContent[localizationKeys.subjectName]}
                </span>
                <FormikInput
                  fluid
                  name="name"
                  className="w-64 rtl:mr-8 ltr:ml-8"
                />
              </div>
              <div className="grid grid-cols-2 items-center justify-center w-64">
                <span className="text-center font-bold mt-4">
                  {selectedContent[localizationKeys.educationalLevels]}
                </span>
                <FormikSelect
                  options={educationalLevels}
                  name="education_levels"
                  className="w-64 mt-6 rtl:mr-4 ltr:ml-4"
                  multiple
                  onChangeCallback={(e) => {
                    if (
                      !e
                        .map((a) => a === "62b09a853288312c26b80c8b")
                        .includes(true)
                    )
                      formik.setFieldValue("secondary_tracks", "");
                    formik.setFieldTouched("secondary_tracks", false);
                  }}
                />
              </div>
              <div className="grid grid-cols-2 items-center justify-center w-56">
                <span className="text-center font-bold mt-4">
                  {selectedContent[localizationKeys.tracks]}
                </span>
                <FormikSelect
                  options={tracks}
                  name="secondary_tracks"
                  className="w-64 mt-6 rtl:mr-8 ltr:ml-8"
                  label={selectedContent[localizationKeys.collegeName]}
                  multiple
                  disabled={
                    !isSecondaryEducationalLevel(
                      formik?.values?.education_levels
                    )
                  }
                />
              </div>
              <div className="flex items-center rtl:float-left ltr:float-right pb-8 mt-6">
                <Button
                  type="button"
                  className="rounded-md bg-white "
                  onClick={() => handleModalClose()}
                >
                  {selectedContent[localizationKeys.cancel]}
                </Button>
                <Button
                  type="submit"
                  primary
                  className="rounded-md w-28"
                  disabled={loadEditingSubject || loadPostingSubject}
                >
                  {isEditingSubject
                    ? selectedContent[localizationKeys.edit]
                    : selectedContent[localizationKeys.create]}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
};

export default AddSubject;
