import React from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router-dom";
import {
  Confirm,
  Dimmer,
  Loader,
  Popup,
  Progress,
  Segment,
} from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import { FaArrowRight } from "react-icons/fa";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import useFilter from "../../lib/use-filter";
import PaginationComponent from "../../components/shared/pagination";
import ViewJobDetails from "../jobs/view-job-details";
import imageDefault from "../../assets/pictures/imageDefault.svg";
import JobsNotFoundIcon from "../../assets/icons/jobs-notFound-icon";
import ImageEmptyState from "../../assets/icons/image-empty-state";
import { BsInfoCircle } from "react-icons/bs";
import routes from "../../routes";

const SchoolProfilePage = () => {
  const params = useParams();
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [isViewingJob, setIsViewingJob] = React.useState(false);
  const [job, setJob] = React.useState("");

  const [limit] = React.useState("8");
  const [totalPages, setTotalPages] = React.useState(0);
  const [jobs, setJobs] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const { run, isLoading, data: school } = useAxios({ data: [] });
  const { run: getSchoolJobs, isLoading: isLoadingJobs } = useAxios({
    data: [],
  });

  React.useEffect(() => {
    run(authAxios.get(`${api.schools.all}/${params?.id}`)).catch((e) =>
      e?.errors?.map((s) => toast.error(s.message))
    );
  }, [params?.id, run]);

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page)
      getSchoolJobs(
        authAxios
          .get(
            `${api.jobs.all}?school=${params?.id}&limit=${limit}&page=${page}`
          )
          .then(({ data }) => {
            setTotalPages(data?.totalPages);
            if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
            setJobs(data);
          })
      ).catch((e) => e?.errors?.map((s) => toast.error(s.message)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSchoolJobs, limit, page, params?.id]);

  const handleDelete = (e) => {
    setIsDeleting(true);
  };

  function handleDeleteSchool() {
    let id = params?.id;
    run(authAxios.delete(`/schools/${id}`))
    .then(() => {
      setTimeout(() => {
        history.push(routes.schools.all);
        toast.success('Deleted Successfuly')
        }, 1000);
        setIsDeleting(false);
      })
      .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
  }

  return (
    <div>
      {isLoading ? (
        <Loader active={true} />
      ) : (
        <div>
          <div
            className="bg-white w-full h-16 flex items-center
            justify-between px-6 shadow-sm rounded-md"
          >
            <div className="flex items-center font-extrabold">
              <FaArrowRight
                className="ltr:rotate-180 mx-2 hover:cursor-pointer"
                onClick={() =>
                  // history.push(routes.schools.all)
                  history.goBack()
                }
              />
              {school?.data?.logo_link ? (
                <img
                  src={school?.data?.logo_link}
                  alt=""
                  className="w-12 h-12 rounded-full mx-2 shadow-md"
                />
              ) : (
                <div className="w-12 h-12 rounded-full flex items-center justify-center mx-2 bg-[#F2F2F2]">
                  <ImageEmptyState className="fill-[#D5D5D5]" />
                </div>
              )}
              {lang === "ar" ? school?.data?.name?.ar : school?.data?.name?.en}
            </div>

            <div className="flex items-center text-sm text-primary gap-2">
              <span> {selectedContent[localizationKeys.freeTrial]}</span>
              <span> {3 - school?.data?.vacancies_limit}/3</span>
              <Progress
                percent={((3 - school?.data?.vacancies_limit) / 3) * 100}
                size="tiny"
                className="mt-5 w-24 z-10 bg-[#177BA31A]"
              />
            </div>
          </div>
          <div className="grid grid-cols-2 w-auto my-6">
            <div className="divide-y divide-dashed">
              <div className="px-4">
                {school?.data?.breif ? (
                  school?.data?.breif
                ) : (
                  <div className="flex items-center justify-center text-center h-24 text-greyText">
                    {selectedContent[localizationKeys.schoolDescription]}...
                  </div>
                )}{" "}
              </div>
              <div className="flex-col mb-2 px-4 mt-4">
                <div>
                  <div className="grid grid-cols-2 w-1/2 mt-4">
                    <span className="text-greyText rtl:ml-2 ltr:mr-2">
                      {selectedContent[localizationKeys.schoolType]}:
                    </span>
                    {school?.data?.school_type ? (
                      <span className="">{school?.data?.school_type}</span>
                    ) : (
                      <span className="text-sm text-greyText">
                        {selectedContent[localizationKeys.notCompleted]}
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-2 w-1/2 mt-2">
                    <span className="text-greyText rtl:ml-2 ltr:mr-2">
                      {selectedContent[localizationKeys.studentCategory]}:
                    </span>
                    {school?.data?.students_gender?.length ? (
                      <span>
                        {school?.data?.students_gender[0]}
                        {school?.data?.students_gender[1] && (
                          <span>- {school?.data?.students_gender[1]}</span>
                        )}
                      </span>
                    ) : (
                      <span className="text-sm text-greyText">
                        {selectedContent[localizationKeys.notCompleted]}
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-2 w-1/2 mt-2">
                    <span className="text-greyText rtl:ml-2 ltr:mr-2">
                      {selectedContent[localizationKeys.educationalOffice]}:
                    </span>
                    {school?.data?.education_centers?.name ? (
                      <span> {school?.data?.education_centers?.name}</span>
                    ) : (
                      <span className="text-sm text-greyText">
                        {selectedContent[localizationKeys.notCompleted]}
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-2 w-1/2 mt-2">
                    <span className="text-greyText">
                      {selectedContent[localizationKeys.educationalLevels]}:
                    </span>
                    {school?.data?.education_levels.length > 0 ? (
                      <span className="w-max">
                        {school?.data?.education_levels?.map((d, index) => (
                          <>
                            <span className="mx-0.5">{d?.name}</span>
                            {index !==
                              school?.data?.education_levels?.length - 1 && (
                              <span>-</span>
                            )}
                          </>
                        ))}
                      </span>
                    ) : (
                      <span className="text-sm text-greyText">
                        {selectedContent[localizationKeys.notCompleted]}
                      </span>
                    )}
                  </div>
                  <div className="grid grid-cols-2 w-1/2 mt-2">
                    <span className="text-greyText rtl:ml-2 ltr:mr-2 ">
                      {
                        selectedContent[
                          localizationKeys.commercialRegistrationNumber
                        ]
                      }
                      :
                    </span>
                    {school?.data?.commercial_registration_num ? (
                      <span> {school?.data?.commercial_registration_num}</span>
                    ) : (
                      <span className="text-sm text-greyText">
                        {selectedContent[localizationKeys.notCompleted]}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="px-3 mt-5">
                <div className="grid grid-cols-2 mt-6 w-1/2">
                  <span className="text-greyText rtl:ml-2 ltr:mr-2">
                    {selectedContent[localizationKeys.city]}:
                  </span>
                  {school?.data?.city?.name ? (
                    <span>{school?.data?.city?.name}</span>
                  ) : (
                    <span className="text-sm text-greyText">
                      {selectedContent[localizationKeys.notCompleted]}
                    </span>
                  )}
                </div>
                <div className="grid grid-cols-2 mt-2 w-1/2">
                  <span className="text-greyText rtl:ml-2 ltr:mr-2">
                    {selectedContent[localizationKeys.address]}:
                  </span>
                  {school?.data?.address ? (
                    <span className="w-max">{school?.data?.address}</span>
                  ) : (
                    <span className="text-sm text-greyText">
                      {selectedContent[localizationKeys.notCompleted]}
                    </span>
                  )}
                </div>
                <div className="grid grid-cols-2 mt-2 w-1/2">
                  <span className="text-greyText rtl:ml-2 ltr:mr-2 w-max">
                    {selectedContent[localizationKeys.email]}:
                  </span>
                  <span className="w-max">{school?.data?.email}</span>
                </div>
                <div className="grid grid-cols-2 mt-2 w-1/2">
                  <span className="text-greyText rtl:ml-2 ltr:mr-2">
                    {selectedContent[localizationKeys.phoneNumber]}:
                  </span>

                  <span className="w-max" dir="ltr">
                    {school?.data?.phone_country_code}
                    {school?.data?.phone}
                  </span>
                </div>
                <div className="my-8">
                  <button
                    type=""
                    onClick={handleDelete}
                    className="hover:bg-red-600 duration-300 bg-red-400 px-4 py-2 text-white font-semibold text-base rounded-lg"
                  >
                    {lang === "en" ? "delete school" : "حذف المدرسه"}
                  </button>
                </div>
              </div>
            </div>
            <div>
              {school?.data?.image_link ? (
                <img
                  alt=""
                  src={school?.data?.image_link}
                  className="w-[32rem] h-52 rounded-xl rtl:float-left ltr:float-right hover:cursor-pointer mx-5"
                  onClick={() => window.open(school?.data?.image_link)}
                />
              ) : (
                <div
                  className="w-[32rem] h-52 rounded-xl rtl:float-left ltr:float-right
                flex items-center justify-center mx-5 bg-gray-100"
                >
                  <img alt="" src={imageDefault} />
                </div>
              )}
            </div>
          </div>
          <div className="font-extrabold text-lg mt-4">
            {selectedContent[localizationKeys.schoolPublishedJobs]} (
            {jobs?.totalLength}):
          </div>
          {jobs?.totalLength > 0 ? (
            <>
              <Segment className="p-0 -py-1 border-0 bg-transparent m-0 shadow-none">
                <Dimmer active={isLoadingJobs} inverted>
                  <Loader size="tiny" inverted />
                </Dimmer>
                {jobs?.data?.length > 0 && (
                  <>
                    <div className="">
                      <div className="flex gap-4 items-center flex-wrap mt-4">
                        {jobs?.data?.map((d) => (
                          <div
                            className="bg-[#F3F0FF] border-2 border-[#EEE9FF] h-32 w-60 mb-2 rounded-lg
                hover:cursor-pointer text-xl text-primary"
                            onClick={() => {
                              setIsViewingJob(true);
                              setJob(d);
                            }}
                          >
                            {d?.isSchoolNameHidden && (
                              <div className="flex items-center mt-1 float-left p-1 mx-1 bg-[#E6E6E6] text-[#909090] rounded-3xl font-normal text-sm">
                                {
                                  selectedContent[
                                    localizationKeys.hiddenIdentity
                                  ]
                                }
                                <Popup
                                  trigger={<BsInfoCircle className="mx-1" />}
                                  position="top right"
                                  className="rounded-xl -mt-0.5 p-2 w-60 border-[#C5E6F3] before:bg-transparent  before:shadow-none"
                                >
                                  <Popup.Content className="text-center text-sm text-[#909090]">
                                    {
                                      selectedContent[
                                        localizationKeys.hiddenIdentityInfo
                                      ]
                                    }
                                  </Popup.Content>
                                </Popup>
                              </div>
                            )}
                            <div className="text-center justify-self-center mt-12">
                              {d?.title}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </Segment>
              <div className="flex items-center justify-center">
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  className="my-8 rtl:flex-row-reverse"
                />
              </div>
            </>
          ) : (
            <div className="flex-col justify-center h-40 mb-20 mt-10">
              <JobsNotFoundIcon size="" />
              <div className="text-center mt-8 text-xl text-primary">
                {selectedContent[localizationKeys.schoolJobsNotFound]}
              </div>
            </div>
          )}
        </div>
      )}
      <ViewJobDetails
        isOpen={isViewingJob}
        onClose={() => {
          setIsViewingJob(false);
          setJob("");
        }}
        job={job}
      />
      <Confirm
        closeOnEscape
        className="text-center font-extrabold text-black text-xl "
        cancelButton={selectedContent[localizationKeys.cancel]}
        confirmButton={
          isLoading ? ".....loading" : selectedContent[localizationKeys.delete]
        }
        content={selectedContent[localizationKeys.deleteSubject]}
        size="mini"
        open={isDeleting}
        onCancel={() => setIsDeleting(false)}
        onConfirm={handleDeleteSchool}
      />
    </div>
  );
};

export default SchoolProfilePage;
