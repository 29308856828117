import React from "react";
import toast from "react-hot-toast";
import { Button, Confirm, Loader, Modal } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { ImPencil, ImBin } from "react-icons/im";
import { BsPlus } from "react-icons/bs";
import useFilter from "../../lib/use-filter";
import PaginationComponent from "../../components/shared/pagination";
import { useLocation } from "react-router-dom";
import AddSubject from "./add-subject";
import { FaEye } from "react-icons/fa";
import SubjectsIcon from "../../assets/icons/subjects-icon";
import { truncateString } from "../../utils/string";
import SubjectsNotFoundIcon from "../../assets/icons/subjects-notFound-icon";

const AllSubjectsPage = () => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const [forceReload, setForceReload] = React.useState(false);
  const [isAddingSubject, setIsAddingSubject] = React.useState(false);
  const [isEditingSubject, setIsEditingSubject] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isViewingSubject, setIsViewingSubject] = React.useState(false);
  const [subject, setSubject] = React.useState([]);

  const [limit] = React.useState("9");
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);

  const { run, isLoading } = useAxios({ data: [] });
  const { run: deleteSubject } = useAxios({ data: [] });

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      run(
        authAxios.get(`${api.subjects.all}?limit=${limit}&page=${parsed.page}`)
      )
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          // if (page > data?.totalPages) setPage(1);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setData(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, parsed?.page, run, forceReload]);

  const DeleteSubject = (id) => {
    deleteSubject(authAxios.delete(`${api.subjects.all}/${id}`))
      .then(() => {
        toast.success(selectedContent[localizationKeys.afterDeletionMessage]);
        setIsDeleting(false);
        setForceReload((p) => !p);
        setSubject("");
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  return (
    <div>
      {isLoading ? (
        <Loader active={true} />
      ) : data?.data?.length > 0 ? (
        <>
          <div className="relative mt-2 mb-10 bg-white px-2 py-1 rounded-xl min-h-[34rem] ">
            <div className="w-full grid grid-cols-4 my-4 bg-gray-100 rounded-md text-lightGrey p-2">
              <div className="rtl:mr-16 ltr:ml-16">
                {selectedContent[localizationKeys.subjectName]}
              </div>
              <div className="rtl:mr-24 ltr:ml-24">
                {selectedContent[localizationKeys.educationalLevels]}
              </div>
              <div className="rtl:mr-32 ltr:ml-32">
                {selectedContent[localizationKeys.tracks]}
              </div>
              <div className="rtl:mr-24 ltr:ml-24">
                {selectedContent[localizationKeys.action]}
              </div>
            </div>
            {data?.data?.map((d) => (
              <div
                className="w-full grid grid-cols-4 py-2 items-center break-words"
                key={d._id}
              >
                <div className=" flex items-center w-full rtl:mr-16 ltr:ml-16">
                  {truncateString(d?.name, 35)}
                </div>
                <div className="rtl:mr-24 ltr:ml-24 w-full">
                  <span>
                    {d?.education_levels.length === 0 && (
                      <span className="">--</span>
                    )}
                  </span>
                  <span>
                    {" "}
                    {d?.education_levels.length && d?.education_levels[0]?.name}
                  </span>
                  {d?.education_levels[1]?.name && (
                    <span> - {d?.education_levels[1]?.name} </span>
                  )}
                  {d?.education_levels[2]?.name && <span>.. </span>}
                </div>
                <div className="rtl:mr-32 ltr:ml-32 w-fit">
                  <div>
                    <span>
                      {d?.secondary_tracks.length === 0 && (
                        <span className="">--</span>
                      )}
                    </span>
                    <span>
                      {" "}
                      {d?.secondary_tracks.length &&
                        d?.secondary_tracks[0]?.name}
                    </span>
                    {d?.secondary_tracks[1]?.name && (
                      <span> - {d?.secondary_tracks[1]?.name} </span>
                    )}
                    {d?.secondary_tracks[2]?.name && <span>.. </span>}
                  </div>
                </div>
                <div className="flex items-center rtl:mr-16 ltr:ml-16">
                  <FaEye
                    className="mx-2 fill-[#414141] cursor-pointer"
                    onClick={() => {
                      setSubject(d);
                      setIsViewingSubject(true);
                    }}
                  />
                  <ImPencil
                    className="mx-2 fill-lightGrey cursor-pointer"
                    onClick={() => {
                      setSubject(d);
                      setIsEditingSubject(true);
                    }}
                  />
                  <ImBin
                    className="mx-2 fill-red-600 cursor-pointer"
                    onClick={() => {
                      setSubject(d);
                      setIsDeleting(true);
                    }}
                  />
                </div>
              </div>
            ))}
            <div className="flex items-center justify-center mx-auto mt-4  mb-4 pb-4">
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                className="absolute bottom-0 rtl:flex-row-reverse mb-4"
              />
            </div>
            <div className="flex items-center justify-end mx-auto">
              <Button
                circular
                size="mini"
                className="bg-primary absolute bottom-0 left-0 h-20 shadow-sm shadow-primary mx-8 mb-2"
                onClick={() => setIsAddingSubject(true)}
              >
                <BsPlus size={36} className="fill-white" />
              </Button>
            </div>
            {/* <div className="absolute bottom-0 mt-32 flex items-center justify-between w-full ">
                <div></div>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  className="my-8 flex justify-center rtl:flex-row-reverse"
                />
                <Button
                  circular
                  size="mini"
                  className="bg-primary h-20 shadow-sm shadow-primary mx-8"
                  onClick={() => setIsAddingSubject(true)}
                >
                  <BsPlus size={36} className="fill-white" />
                </Button>
              </div> */}
          </div>
        </>
      ) : (
        <div className="relative bg-white px-2 py-1 rounded-xl h-[33rem] flex items-center justify-center">
          <div className="flex-col justify-center mx-auto text-center">
            <SubjectsNotFoundIcon
              size="80"
              className="justify-center mx-auto text-center my-4"
            />
            <span className="text-xl text-primary text-center">
              {selectedContent[localizationKeys.subjectsNotFound]}
            </span>
          </div>
          <div className="flex items-center justify-end">
            <Button
              circular
              size="mini"
              className="bg-primary absolute bottom-0 left-0 h-20 shadow-sm shadow-primary mx-8 mb-2"
              onClick={() => setIsAddingSubject(true)}
            >
              <BsPlus size={36} className="fill-white" />
            </Button>
          </div>
        </div>
      )}
      <AddSubject
        isOpen={isAddingSubject || isEditingSubject}
        onClose={() => {
          setIsAddingSubject(false);
          setIsEditingSubject(false);
          setSubject("");
        }}
        reload={() => setForceReload((p) => !p)}
        subject={subject}
        isEditingSubject={isEditingSubject}
      />
      <ViewSubject
        isOpen={isViewingSubject}
        subject={subject}
        onClose={() => {
          setSubject("");
          setIsViewingSubject(false);
        }}
      />
      <Confirm
        closeOnEscape
        className="text-center font-extrabold text-black text-xl "
        cancelButton={selectedContent[localizationKeys.cancel]}
        confirmButton={selectedContent[localizationKeys.delete]}
        content={selectedContent[localizationKeys.deleteSubject]}
        size="mini"
        open={isDeleting}
        onCancel={() => setIsDeleting(false)}
        onConfirm={() => DeleteSubject(subject?._id)}
      />
    </div>
  );
};

const ViewSubject = ({ isOpen, onClose, subject }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      size="mini"
      closeIcon
      className="max-w-screen-2xl"
    >
      <Modal.Content className="">
        <SubjectsIcon className="fill-primary mx-auto" size="24" />
        <span className="flex justify-center mt-2 font-extrabold ">
          {subject?.name}
        </span>
        {subject?.education_levels?.length > 0 && (
          <div className="grid grid-cols-2 items-center justify-center mt-4">
            <span className="text-[#9B9B9B]">
              {selectedContent[localizationKeys.educationalLevels]}
            </span>
            <span className="">
              {subject?.education_levels?.map((d, index) => (
                <>
                  <span className="mx-0.5">{d?.name}</span>
                  {index !== subject?.education_levels?.length - 1 && (
                    <span>-</span>
                  )}
                </>
              ))}
            </span>
          </div>
        )}
        {subject?.secondary_tracks?.length > 0 && (
          <div className="grid grid-cols-2 items-center justify-center mt-4">
            <span className="text-[#9B9B9B]">
              {selectedContent[localizationKeys.tracks]}
            </span>
            <span className="">
              {subject?.secondary_tracks?.map((d, index) => (
                <>
                  <span className="mx-1">{d?.name}</span>
                  {index !== subject?.secondary_tracks?.length - 1 && (
                    <span>-</span>
                  )}
                </>
              ))}
            </span>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default AllSubjectsPage;
