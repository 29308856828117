import React from "react";
import { AiFillEye, AiOutlineArrowRight } from "react-icons/ai";
import {
  BsGenderFemale,
  BsGenderMale,
  BsInfoCircle,
  BsPerson,
  BsTelephone,
} from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { Button, Loader, Popup } from "semantic-ui-react";
import api from "../../api";
import CrownIcon from "../../assets/icons/crown-icon";
import CVIcon from "../../assets/icons/cv-icon";
import EducationalLevelIcon from "../../assets/icons/educational-level-icon";
import EducationalOfficesIcon from "../../assets/icons/educational-offices-icon";
import EducationalTrackIcon from "../../assets/icons/educational-track-icon";
import FlagIcon from "../../assets/icons/flag-icon";
import GlobeIcon from "../../assets/icons/globe-icon";
import LocationIcon from "../../assets/icons/location-icon";
import SubjectsIcon from "../../assets/icons/subjects-icon";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/language-context";
import useAxios from "../../lib/use-axios";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import InstructorProfileSections from "./instructor-profile-sections";

const InstructorProfilePage = () => {
  const params = useParams();
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const {
    run: getInstructor,
    data: instructor,
    isLoading: isGettingInstructor,
  } = useAxios({});

  React.useEffect(() => {
    getInstructor(authAxios.get(`${api.instructor.profile(params?.id)}`));
  }, [getInstructor, params?.id]);

  return (
    <div>
      {isGettingInstructor ? (
        <Loader active={true} size="small" className="mt-4" />
      ) : (
        <>
          <div className="w-full h-16 rounded-lg flex items-center px-6 bg-white ring-1 ring-[#E3E3E3] shadow-sm">
            <div className="flex items-center">
              <AiOutlineArrowRight
                size="20px"
                className="hover:cursor-pointer ltr:rotate-180 mt-1"
                onClick={() => history.goBack()}
              />
              <span
                className="font-extrabold mx-2 text-lg text-black cursor-pointer"
                onClick={() => history.goBack()}
              >
                {lang === "ar"
                  ? instructor?.data?.name?.ar
                  : instructor?.data?.name?.en}
              </span>
            </div>
          </div>
          <div className="min-h-[31rem] bg-white mt-6 rounded-xl ring-1 ring-[#E3E3E3] p-12 mx-2">
            <div className={`grid grid-cols-4`}>
              {instructor?.data?.image_link ? (
                <img
                  alt=""
                  src={instructor?.data?.image_link}
                  className="w-[10.25rem] h-[10.56rem] rounded-xl rtl:float-left ltr:float-right cursor-pointer shadow-md"
                  onClick={() => window.open(instructor?.data?.image_link)}
                ></img>
              ) : (
                <div
                  className="w-[10.25rem] h-[10.56rem] rounded-xl flex items-center justify-center
                rtl:float-left ltr:float-right cursor-pointer shadow-md bg-[#ECECEC]"
                >
                  <FaUserAlt className="w-[2.8rem] h-[3.25rem] fill-[#ADADAD]" />
                </div>
              )}
              <div className="col-span-3">
                <div className="flex items-center mt-2">
                  <BsPerson className="fill-[#177BA3]" size="18px" />
                  <span className="mx-2">
                    {lang === "ar"
                      ? instructor?.data?.name?.ar
                      : instructor?.data?.name?.en}
                  </span>
                  {instructor?.data?.ejadaCertified && (
                    <>
                      <div className="bg-[#6DC43F33] h-7 min-w-[26px] px-2 flex items-center text-[#6DC43F] rounded-lg">
                        <CrownIcon />
                        {selectedContent[localizationKeys.ejada]}
                      </div>
                      <Popup
                        flowing
                        hoverable
                        trigger={
                          <BsInfoCircle className="mx-3 fill-[#6DC43F]" />
                        }
                        position="bottom right"
                        className="rounded-xl p-2 w-60 border-[#C5E6F3] before:bg-transparent before:shadow-none "
                      >
                        <Popup.Content className="text-center text-sm text-[#909090]">
                          {selectedContent[localizationKeys.hiddenIdentityInfo]}
                          <div>
                            <a
                              target="_blank"
                              className="text-primary"
                              href="https://www.ejadalearning.com/"
                              rel="noreferrer"
                            >
                              {" "}
                              {selectedContent[localizationKeys.goToEjad]}
                            </a>
                          </div>
                        </Popup.Content>
                      </Popup>
                    </>
                  )}
                </div>
                <div className="grid grid-cols-3">
                  <div className="flex items-center mt-2">
                    {instructor?.data?.gender === "ذكر" ? (
                      <BsGenderMale className="fill-[#177BA3]" size="18px" />
                    ) : (
                      <BsGenderFemale className="fill-[#177BA3]" size="18px" />
                    )}
                    <span className="mx-2">
                      {lang === "ar"
                        ? instructor?.data?.gender
                        : instructor?.data?.gender}
                    </span>
                  </div>
                  <div className="flex items-center mt-2">
                    <FlagIcon className="fill-[#177BA3]" size="18px" />
                    <span className="mx-2">{instructor?.data?.country}</span>
                  </div>
                  {instructor?.data?.resume_link && (
                    <div className="flex items-center mt-2">
                      <CVIcon className="fill-[#177BA3]" size="18px" />
                      <span
                        className="mx-2 underline cursor-pointer"
                        onClick={() =>
                          window.open(instructor?.data?.resume_link)
                        }
                      >
                        {selectedContent[localizationKeys.cv]}
                      </span>
                      <Button
                        circular
                        icon
                        size="mini"
                        className="bg-[#177BA321] ring-1 ring-[#177BA3] p-2"
                        onClick={() =>
                          window.open(instructor?.data?.resume_link)
                        }
                      >
                        <AiFillEye className="fill-[#177BA3]" size="10px" />
                      </Button>
                    </div>
                  )}
                </div>
                <div className="mt-3 flex items-center text-sm">
                  {instructor?.data?.englishLevel && (
                    <div className="min-h-7 min-w-[220px] px-3 bg-[#EBF9FF] rounded-xl flex items-center">
                      <p>
                        {selectedContent[localizationKeys.englishLanguageLevel]}
                        :
                      </p>
                      <p className="text-primary mx-2">
                        {instructor?.data?.englishLevel}
                      </p>
                    </div>
                  )}
                  <div className="min-h-7 min-w-[66px] px-3 bg-[#EBF9FF] rounded-xl flex items-center mx-3 text-primary">
                    {lang === "ar"
                      ? instructor?.data?.jobPlace[0]?.name?.ar
                      : instructor?.data?.jobPlace[0]?.name?.en}
                  </div>
                  <div className="min-h-7 min-w-[66px] px-3 bg-[#EBF9FF] rounded-xl flex items-center mx-3 text-primary">
                    {lang === "ar"
                      ? instructor?.data?.jobType[0]?.name?.ar
                      : instructor?.data?.jobType[0]?.name?.en}
                  </div>
                </div>
                <div className="mt-2 break-all">
                  {instructor?.data?.summary}
                </div>
              </div>
            </div>
            <div className="min-h-[12rem] bg-white mt-6 rounded-xl p-12 grid grid-cols-3 shadow-[0px_0px_6px_rgba(0,0,0,0.09)]">
              <div>
                {instructor?.data?.education_center && (
                  <div className="flex items-center gap-2">
                    <EducationalOfficesIcon className="fill-primary" />
                    <div>
                      {instructor?.data?.education_center?.name} -{" "}
                      {instructor?.data?.education_center?.gender}
                    </div>
                  </div>
                )}
                <div className="flex items-center gap-2 mt-2">
                  <EducationalLevelIcon />
                  <div>
                    <span className="w-max">
                      {instructor?.data?.education_levels?.map((d, index) => (
                        <>
                          <span className="mx-0.5">{d?.name}</span>
                          {index !==
                            instructor?.data?.education_levels?.length - 1 && (
                            <span>-</span>
                          )}
                        </>
                      ))}
                    </span>
                  </div>
                </div>
                {instructor?.data?.secondary_tracks?.length > 0 && (
                  <div className="flex items-center gap-2 mt-2">
                    <EducationalTrackIcon />
                    <div>
                      <span className="w-max">
                        {instructor?.data?.secondary_tracks?.map((d, index) => (
                          <>
                            <span className="mx-0.5">{d?.name}</span>
                            {index !==
                              instructor?.data?.secondary_tracks?.length -
                                1 && <span>-</span>}
                          </>
                        ))}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <GlobeIcon />
                  <div>{instructor?.data?.city?.name}</div>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <SubjectsIcon className="fill-primary " />
                  <div>
                    <span className="w-max">
                      {instructor?.data?.subject?.map((d, index) => (
                        <>
                          <span className="mx-0.5">{d?.name}</span>
                          {index !== instructor?.data?.subject?.length - 1 && (
                            <span>-</span>
                          )}
                        </>
                      ))}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <BsTelephone className="fill-[#177BA3]" />
                  <div dir="ltr">
                    {instructor?.data?.country_code}
                    {instructor?.data?.phone}
                  </div>
                </div>
              </div>
              <div>
                {instructor?.data?.address && (
                  <div className="flex items-center gap-2">
                    <div>
                      <LocationIcon />
                    </div>
                    <div>{instructor?.data?.address}</div>
                  </div>
                )}
              </div>
            </div>
            <InstructorProfileSections instructorId={params?.id} />
          </div>
        </>
      )}
    </div>
  );
};

export default InstructorProfilePage;
