export const initialState = {
  selected: [],
  subjectList: [],
  viewLimit: 0,
  instructors: [],
  loading: "idle",
  totalPages: 0,
  totalInstructors: 0,
  modal: { open: false, loading: "idle" },
  filterModal: { open: false },
};
const actions = {
  loading: "LOADING",
  viewLimit: "VIEW_LIMIT",
  selected: "SELECTED",
  subjectList: "SUBJECT_LIST",
  instructors: "INSTRUCTORS",
  pages: "TOTAL_PAGES",
  totalInstructors: "TOTAL_INSTRUCTORS",
  pageModal: "PAGE_MODAL",
  filterModal: "FILTER_MODAL",
};
export const reducer = (state, { type, payload }) => {
  switch (type) {
    case actions.loading:
      return {
        ...state,
        loading: payload,
      };
    case actions.viewLimit:
      return {
        ...state,
        viewLimit: payload,
      };
    case actions.selected:
      return {
        ...state,
        selected: payload,
      };
    case actions.subjectList:
      return {
        ...state,
        subjectList: payload,
      };
    case actions.instructors:
      return {
        ...state,
        instructors: payload,
      };
    case actions.pages:
      return {
        ...state,
        totalPages: payload,
      };
    case actions.totalInstructors:
      return {
        ...state,
        totalInstructors: payload,
      };
    case actions.pageModal:
      return {
        ...state,
        modal: payload,
      };
  
    case actions.filterModal:
      return {
        ...state,
        filterModal: payload,
      };
    default:
      return state;
  }
};
export const actionReducers = (dispatch) => ({
  setLoading: (loadingState) =>
    dispatch({ type: actions.loading, payload: loadingState }),
  setViewLimit: (limit) =>
    dispatch({ type: actions.viewLimit, payload: limit }),

  setSelected: (selectedSubjects) =>
    dispatch({ type: actions.selected, payload: selectedSubjects }),
  setSubjectList: (list) =>
    dispatch({ type: actions.subjectList, payload: list }),
  setInstructors: (instructorsList) =>
    dispatch({ type: actions.instructors, payload: instructorsList }),
  setTotalPages: (count) => dispatch({ type: actions.pages, payload: count }),
  setTotalInstructors: (count) =>
    dispatch({ type: actions.totalInstructors, payload: count }),
  setModal: (payload) => dispatch({ type: actions.pageModal, payload }),
  setFilterModal: (payload) => dispatch({ type: actions.filterModal, payload }),
});
