import React from "react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import useAxios from "../../lib/use-axios";
import { Loader } from "semantic-ui-react";
import toast from "react-hot-toast";
import localizationKeys from "../../localization/localization-keys";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import { FaEye } from "react-icons/fa";
import { truncateString } from "../../utils/string";
import useFilter from "../../lib/use-filter";
import routes from "../../routes";
import { useHistory, useLocation } from "react-router-dom";
import PaginationComponent from "../../components/shared/pagination";
import SchoolsNotFoundIcon from "../../assets/icons/school-notFound-icon";

const AllSchoolsPage = () => {
  const history = useHistory();
  const [lang] = useLanguage();
  const selectedContent = content[lang];

  const { run, isLoading } = useAxios({ data: [] });

  const [limit] = React.useState("8");
  const [totalPages, setTotalPages] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      run(
        authAxios.get(`${api.schools.all}?limit=${limit}&page=${parsed.page}`)
      )
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          // if (page > data?.totalPages) setPage(1);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setData(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, parsed?.page, run]);

  return (
    <div>
      {" "}
      {isLoading ? (
        <Loader active={true} />
      ) : data?.data?.length > 0 ? (
        <>
          <div className="relative mb-10 bg-white px-2 py-1 rounded-xl min-h-[36rem] ">
            <div className="w-full grid grid-cols-7 my-4 bg-gray-100 rounded-md text-lightGrey p-2">
              <div className="rtl:mr-10 ltr:ml-10">
                {selectedContent[localizationKeys.schoolName]}
              </div>
              <div className="rtl:mr-24 ltr:ml-24 w-full">
                {selectedContent[localizationKeys.educationalLevels]}
              </div>
              <div className="rtl:mr-32 ltr:ml-32 w-full">
                {selectedContent[localizationKeys.educationalOffice]}
              </div>
              <div className="rtl:mr-36 ltr:ml-36">
                {selectedContent[localizationKeys.city]}
              </div>
              <div className="rtl:mr-28 ltr:ml-28">
                {selectedContent[localizationKeys.type]}
              </div>
              <div className="rtl:mr-16 ltr:ml-16 w-full">
                {selectedContent[localizationKeys.studentCategory]}
              </div>
              <div className="rtl:mr-12 ltr:ml-12">
                {selectedContent[localizationKeys.watch]}
              </div>
            </div>
            {data?.data?.map((d) => (
              <div
                className="w-full grid grid-cols-7 py-2 items-center
                hover:rounded-lg transition-all duration-300 ease-in hover:-translate-y-1 hover:shadow-sm"
                key={d._id}
              >
                <div className="rtl:mr-10 ltr:ml-10 w-max">
                  {lang === "ar"
                    ? truncateString(d?.name?.ar, 30)
                    : truncateString(d?.name?.en, 25)}
                </div>
                <div className="rtl:mr-24 ltr:ml-24 w-full">
                  <span>
                    {d?.education_levels.length === 0 && (
                      <span className="">--</span>
                    )}
                  </span>
                  <span>
                    {" "}
                    {d?.education_levels.length && d?.education_levels[0]?.name}
                  </span>
                  {d?.education_levels[1]?.name && (
                    <span> - {d?.education_levels[1]?.name} </span>
                  )}
                  {d?.education_levels[2]?.name && <span>.. </span>}
                </div>
                <div className="rtl:mr-32 ltr:ml-32 w-full">
                  {d?.education_centers?.name ? (
                    <span>{d?.education_centers?.name}</span>
                  ) : (
                    <span className="">--</span>
                  )}
                </div>
                <div className="rtl:mr-36 ltr:ml-36">
                  {d?.city?.name ? (
                    <span>{d?.city?.name}</span>
                  ) : (
                    <span className="">--</span>
                  )}
                </div>
                <div className="rtl:mr-28 ltr:ml-28">
                  {d?.school_type ? (
                    <span>{d?.school_type}</span>
                  ) : (
                    <span className="">--</span>
                  )}
                </div>
                <div className="rtl:mr-16 ltr:ml-16">
                  {d?.students_gender?.length ? (
                    <span>
                      {d?.students_gender[0]}
                      {d?.students_gender[1] && (
                        <span>- {d?.students_gender[1]}</span>
                      )}
                    </span>
                  ) : (
                    <span className="">--</span>
                  )}
                </div>
                <div className="rtl:mr-16 ltr:ml-16 ">
                  <FaEye
                    className="text-center hover:cursor-pointer"
                    size={16}
                    onClick={() => history.push(routes.schools.profile(d._id))}
                  />
                </div>
              </div>
            ))}
            <div className="flex items-center justify-center mx-auto w-full mb-4 pb-4 mt-6">
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                className="absolute bottom-0 rtl:flex-row-reverse mb-4"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="bg-white px-2 py-1 rounded-xl h-[33rem] flex items-center justify-center">
          <div className="flex-col">
            <SchoolsNotFoundIcon
              size="80"
              className="justify-center mx-auto text-center my-4"
            />
            <span className="text-xl text-primary text-center">
              {selectedContent[localizationKeys.schoolsNotFound]}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllSchoolsPage;
