import React, { useState } from "react";
import ChangePassword from "../../components/shared/ChangePassword";
import { Image } from "semantic-ui-react";
import loginImage from "../../assets/pictures/login.png";
import logo from "../../assets/pictures/logo.svg";
import routes from "../../routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLanguage } from "../../lib/language-context";

const ForgetPasswordLayout = () => {
  const history = useHistory();
  const [lang, setLang] = useLanguage();
  const [data, setData] = useState({
    password: "",
    cPasswrod: "",
  });

  const backToLogin = () => {
    history.push(routes.login);
  };

  const getData = (e) => {
    const copyData = { ...data };
    copyData[e.target.name] = e.target.value;
    console.log(copyData);
    //setData(copyData)
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
  };

  return (
    <div
      className="w-screen h-screen flex items-center justify-center bg-slate-50"
      style={{
        backgroundImage: `url(${loginImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="bg-white rounded-lg px-4 py-4 max-w-lg flex-grow">
        <Image src={logo} className=" text-center mx-auto " />

        <ChangePassword />
        <div>
          <button
            onClick={backToLogin}
            type=""
            className=" text-blue-600 hover:text-blue-600 duration-300 text-lg font-medium underline"
          >
            {lang === "en" ? "back to login" : "  تسجيل الدخول"} &nbsp; &#8592;
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordLayout;
